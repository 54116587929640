<template>
  <div class="home">
    <div class="home-header">
      <div class="header-title">
        <div>
          <a href="JavaScript:;" class="header-title-log" @click="homeHand">
            <img :src="jqjklogo" alt="" />
            <p>海南步洛健康互联网医院有限公司</p>
          </a>
        </div>
        <div class="header-title-button" v-if="!token && type === '0'">
          <button @click="register">注册</button>
          <button @click="LoginAccount">登入</button>
        </div>
        <div class="header-title-button1" v-if="token">
          <span>hi,欢迎你 {{ name }} </span>
          <el-button type="text" @click="outLoginHand">退出登录</el-button>
        </div>
        <div class="header-title-button2" v-if="!token && type === '1'">
          <button @click="outHome">返回首页</button>
        </div>
      </div>
    </div>
    <router-view />
    <div class="home-footer">
      <div class="home-forter-box">
        <div class="home-footer-text-first">
          <span>公司名称：海南步洛健康互联网医院有限公司</span>
          <p>|</p>
          <span
            >注册地址：海南省澄迈县老城镇高新技术产业示范区海南生态软件园8817幢3层</span
          >
        </div>
        <div class="home-footer-text-second">
          <a
            href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
            target="_blank"
            >备案号：琼ICP 备 2021002134 号-1</a
          >
          <p>|</p>
          <span>互联网药品信息服务许可证编号：(琼)-经营性-2021-0009</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      jqjklogo: "https://static.jinqimedical.com/jqjklogo.png", //锦奇健康logo
      token: "",
      type: "",
      name: "",
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    const timer = setInterval(() => {
      this.token = localStorage.getItem("token");
      const data = JSON.parse(localStorage.getItem("currentUserName"))
        ? JSON.parse(localStorage.getItem("currentUserName"))
        : "";
      this.name = data.realName;
    }, 500);
    this.$once("hook:beforeDestroy", () => {
      setInterval(timer);
    });

    const data = JSON.parse(localStorage.getItem("currentUserName"))
      ? JSON.parse(localStorage.getItem("currentUserName"))
      : "";
    this.name = data.realName;
    sessionStorage.setItem("type", "0");
    this.changeHand();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    register() {
      //注册
      this.$router.push({ path: "/register" });
      sessionStorage.setItem("type", "1");
      this.type = "1";
    },
    LoginAccount() {
      //登入
      this.$router.push({ path: "/login" });
      sessionStorage.setItem("type", "1");
      this.type = "1";
    },
    outHome() {
      this.$router.push({ path: "/" });
      sessionStorage.setItem("type", "0");
      this.type = "0";
    },
    outLoginHand() {
      localStorage.removeItem("token");
      this.$router.push("/home/workplaceInformation");
      sessionStorage.setItem('type','0')
      // const times = setTimeout(() => {
      //   window.location.reload();
      // }, 800);
      // this.$once("hook:beforeDestroy", () => {
      //   clearInterval(times);
      // });

      this.$message({
        message: "退出登入",
        type: "warning",
      });
    },

    changeHand() {
      const timer = setInterval(() => {
        this.type = sessionStorage.getItem("type");
      }, 0);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    },
    homeHand() {
      this.$router.push({ path: "/" });
      sessionStorage.removeItem("pages");
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.home {
  min-width: 1184px;
  height: auto;
  display: flex;
  flex-direction: column;
}
//首页头部
.home-header {
  max-width: 1920px;
  height: 76px;
  background: #ffffff;
  border-bottom: 1px solid rgba(48, 46, 55, 0.1);
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 101;
  .header-title {
    min-width: 1184px;
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-title-log {
      display: flex;
      align-items: center;
      text-decoration: none;
      :nth-child(1) {
        > img {
          width: 36px;
          height: 36px;
        }
      }
      :nth-child(2) {
        min-width: 330px;
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 30px;
      }
    }
    //登入 | 注册按钮
    .header-title-button {
      min-width: 68px;
      > :nth-child(1) {
        width: 58px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px;
        border: 1px solid #ff7d3e;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff7d3e;
        line-height: 13px;
      }
      > :nth-child(2) {
        width: 58px;
        height: 28px;
        background: #ff7d3e;
        border-radius: 14px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        border: none;
        color: #ffffff;
        line-height: 13px;
        margin-left: 20px;
      }
    }
  }
}
//首页页脚
.home-footer {
  max-width: 1920px;
  height: 100px;
  background: #ffffff;
  display: flex;
  justify-content: center;

  > .home-forter-box {
    min-width: 1184px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.home-footer-text-first,
.home-footer-text-second {
  > :nth-child(1) {
    width: 240px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(48, 46, 55, 0.6);
    line-height: 17px;
    text-decoration: none;
  }
  > :nth-child(2) {
    font-size: 10px;
    color: #d8d8d8;
    display: initial;
    margin: 0 9px;
  }
  > :nth-child(3) {
    width: 417px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(48, 46, 55, 0.6);
    line-height: 17px;
  }
}
.header-title-button1 {
  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(48, 46, 55, 0.7);
    line-height: 20px;
  }
  button {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff7d3e;
    line-height: 20px;
  }
}
.header-title-button2 {
  min-width: 80px;
  button {
    width: 80px;
    height: 28px;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #ff7d3e;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff7d3e;
    line-height: 13px;
  }
}
</style>
